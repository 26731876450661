import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function WeekSummary() {
  return (
    <>
    {/* <span className="italic bold">dominant</span> */}
    {/* <span className="bold">dominant</span> */}
      <div className="section section-weekly-summary">
        <Container>
          <Row>
            <Col md="12">
            <p className="category">

              {/* <YoutubeEmbed embedId="yiFNrO33bSo"></YoutubeEmbed><br></br> */}
              Boy it’s so much <span className="bold red">heat in the kitchen</span>, you can roast a marshmallow off a               
              team’s ass! Whether it’s belt to ass, boot to ass, or ass to kiss this has turned into 
              a <span className="bold green">battle royal</span> for us all! Majority of the top team’s loss this week putting some 
              familiar faces back in the hunt! It’s still <span className="bold blue">so much ground to cover</span> and 6 teams are 
              in the thick of it! With the Big dawg <span className="bold">THE BIG D</span> still at the top the question is
               who is gonna be there at the end!

            {/* Congrats on what's gotta be the most <span className="bold green">entertaining</span>, <span className="bold blue">competitive</span> season in the history of the <span className="bold">League</span>. To the teams that didn't make the playoffs, we thank you for your <span className="bold orange">grit and determination</span>. <br/><br/>
            <div className="text-center"> On the AFC's side, we got two teams:</div>
            <br/>
            <span className="bold">BAMA BLACKOUT:</span> He's been leading the pack all year after starting out 8-0. Can he break through for the first time in 6 years?<br/><br/> */}
            
            </p>
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/devin-white.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeekSummary;

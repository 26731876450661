import React from "react";
import Matchup from "./Matchup";
// reactstrap components
import{
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container, 
  Row
} from "reactstrap";

// core components

function Matchups() {
    // const matchups = [
    //     {"winner": "Double Trouble", "loser": "Bama BlackOut", 
    //         "text": "This one was a high scoring affair, with the two highest scoring teams this week squaring off. It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!"},

    //     {"winner": "! Zo", "loser": "Dandy MEN", 
    //         "text": "Deshaun Watson and Will Fuller set the tone early on Thanksgiving day, and <span className='bold team-name-tag'>Dandy MEN</span> never let up, handing <span className='bold team-name-tag'>! Zo</span> his third straight loss. This loss all but eliminates ! Zo from the playoffs. Dandy can clinch his first ever playoff birth with a win next week against Bama. I'm not gonna lie, I'm rooting for the boy Dandy, even though it's me (Bama) he's playing next week!!!!!"},

    //     {"winner": "The Goonz", "loser": "THE Big D", 
    //         "text": "Dominant. <span className='bold team-name-tag'>The Goonz</span> making it look easy once again to get to the magical <i>SEVENTH</i> win this week putting an exclamation point on an impressive season knocking off <span className='bold team-name-tag'>The Big D</span>. D has really struggled this year following an impressive draft, but has vowed to have studied The League like a playbook and knows what all he did wrong in 2020. Their won't be no easy outs in 2021!!!!!"},


    // ];
  return (
    <>
      <div className="section section-weekly-recap">
        <Container>
            <br />
            <br />
            <br />

            {/* {matchups.map(function(matchup, idx){
                 return (<Matchup key={idx} winner={matchup.winner} loser={matchup.loser} text={matchup.text} />)
             })} */}
        {/* <Matchup winner="Double Trouble" loser="Bama BlackOut" 
        text="This one was a high scoring affair, with the two highest scoring teams this week squaring off. 
        It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping 
        the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the 
        league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!" /> */}
        <Row>
        <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>                            
                <span className="bold">You can never count him out</span>! <span className="bold team-name-tag">LET ME SEE YOUR TDs</span> appeared 
                to be <span className="bold red">going down with the ship</span> then a super charge of energy took over as he dominated 
                against <span className="bold team-name-tag">The Goonz</span>! <span className="italic bold">The Bills D</span> gave him <span className="bold blue">more than enough</span> to 
                move on while The Goonz was <span className="bold orange">looking for points</span>! Back in the hunt TDs has his sights set on what’s next!
                </CardText>          
                </CardBody>
            </Card>   
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">The Goonz</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">LET ME SEE YOUR TDs</CardTitle>          
                </CardBody>
            </Card>            
          </Row>
            <br/>
            <br/>
          <Row>
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Invisible Juice</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">Bama BlackOut</CardTitle>          
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>    
                
                <span className="bold">Guess who’s back</span>! <span className="bold team-name-tag">Bama BlackOut</span> has to
                <span className="bold green"> put that shit in 5th gear</span> and starting to make his run as he takes down middle of the pack <span className="bold team-name-tag">Invisible Juice</span>! 
                Bama is <span className="bold blue">hungry</span> at this point and I wouldn’t want to get caught up in that shit! As for Juice, solid performance just <span className="bold red">not enough to keep up</span>! It’s about to get real!
                </CardText>          
                </CardBody>
            </Card> 
          </Row>
            <br/>
            <br/>
          <Row>            
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                <span className="bold team-name-tag">King Jaffe Joffer</span> put belt to ass on top team <span className="bold team-name-tag">Double Trouble</span>, as 
                he told him he’s <span className="bold green">Uncle Willie’s son</span>! KJJ has <span className="bold">Joe Burrow</span> putting 
                on a <span className="bold blue">monster performance</span> while DT made the attempt to come back. It just <span className="bold red">wasn’t enough</span> to secure the victory!
                </CardText>          
                </CardBody>
            </Card>     
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Double Trouble</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">King Jaffe Joffer</CardTitle>          
                </CardBody>
            </Card> 
          </Row>
          <br/>
            <br/>
          <Row>       
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Sport Of Kings</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">The Big D</CardTitle>          
                </CardBody>
            </Card>        
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>                    
                <span className="bold">Down goes Frazier, Down goes Frazier</span>! <span className="bold team-name-tag">Sport Of Kings</span> came 
                to fight off the <span className="bold red">bad mojo</span> he’s been receiving! Thanks to the combined efforts of Ridley and Herbert, he put together 
                a nice win over the <span className="bold green">league superstar</span> <span className="bold team-name-tag">The Big D</span>!  The Big D had 
                a down week but that win can do <span className="bold blue">wonders for your confidence</span>!
                {/* <img
                  alt="..."
                  src={require("assets/img/broke-make-it-rain.gif").default}
                ></img> */}
              
                </CardText>          
                </CardBody>
            </Card>               
          </Row>
          <br/>
          <br/>
          <Row>
          <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>       
                The <span className="bold green">phoenix is starting to rise</span> from the ashes! <span className="bold team-name-tag">TOO Easy</span> has 
                been quietly but quickly getting back in the hunt with a <span className="bold blue">statement victory</span> over <span className="bold team-name-tag">Dandy MEN</span>!!!!!! Easy 
                had <span className="bold">Ja'marr Chase</span>, who became the <span className="bold red">difference maker</span> for him and is starting to <span className="bold orange">piece together them Ws</span>! 
                </CardText>          
                </CardBody>
            </Card>                  
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">TOO Easy</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">Dandy MEN</CardTitle>          
                </CardBody>
            </Card>
          </Row>
          <br/>
          <br/>
          <Row>            
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Zeke's Supreme Team</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">! Zo</CardTitle>
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                You can literally see <span className="bold team-name-tag">Zeke's Supreme Team</span> <span className="bold green">swinging with everything he got</span> week 
                after week, but again another <span className="bold red">heart breaking defeat</span> from <span className="bold team-name-tag">! Zo</span>! 
                Purdy showed up big and with Zeke not getting <span className="bold orange">nothing from nobody</span>, it was hard to stay in the game!
                </CardText>          
                </CardBody>
            </Card>      
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Matchups;
